import { twJoin } from '@troon/tailwind-preset/merge';
import { Show } from 'solid-js';
import type { JSX, ParentProps } from 'solid-js';

type Props = ParentProps<{
	title: JSX.Element;
	subtext?: JSX.Element;
}>;

export function PageHeader(props: Props) {
	return (
		<header class="grid grid-cols-12 items-center gap-4 lg:gap-8">
			<div class={twJoin('col-span-12 flex w-full flex-col gap-4', !!props.children && 'lg:col-span-8')}>
				<h1 class="text-3xl font-semibold md:text-5xl">{props.title}</h1>
				<div class="text-base text-neutral-800 md:text-lg">{props.subtext}</div>
			</div>

			<Show when={props.children}>
				<div class="col-span-12 flex justify-end lg:col-span-4">{props.children}</div>
			</Show>
		</header>
	);
}
